<template>
  <div class="filter-wrap">
    <label for="filter" class="label">筛选:</label>
     <div class="filter-item" @click.stop="chooseFilterItem(4)" :class="{'active': index == 4 && filter.show}">
      <span>{{ FILTER.searchtype[filter.searchtype]&&FILTER.searchtype[filter.searchtype].name || '模式' }}</span>
      <img :src="arrow" class="arrow"> 
      <ul v-show="index == 4 && filter.show">
        <li @click.stop="chooseFilterSearchType(-1)">系统默认</li>
        <li v-for="item in filter_search_type_keys" :key="FILTER.searchtype[item].name" @click.stop="chooseFilterSearchType(item)">{{FILTER.searchtype[item].name}}</li>
      </ul>
    </div>

    <div class="filter-item type" @click.stop="chooseFilterItem(1)" :class="{'active': index == 1 && filter.show}">
      <span>{{ FILTER.type[filter.type]&&FILTER.type[filter.type].name || '文件类型' }}</span>
      <img :src="arrow" class="arrow">
      <ul v-show="index == 1 && filter.show">
        <li @click.stop="chooseFilterType(-1)">全部类型</li>
        <li v-for="item in filter_type_keys" :key="FILTER.type[item].name" @click.stop="chooseFilterType(item)">{{FILTER.type[item].name}}</li>
      </ul>
    </div>
    <div class="filter-item size" @click.stop="chooseFilterItem(2)" :class="{'active': index == 2 && filter.show}">
      <span>{{ FILTER.size[filter.size]&&FILTER.size[filter.size].name || '文件大小' }}</span>
      <img :src="arrow" class="arrow">
      <ul v-show="index == 2 && filter.show">
        <li @click.stop="chooseFilterSize(-1)">全部大小</li>
        <li v-for="item in filter_size_keys" :key="FILTER.size[item].name" @click.stop="chooseFilterSize(item)">{{FILTER.size[item].name}}</li>
      </ul>
    </div>
    <div class="filter-item" @click.stop="chooseFilterItem(3)" :class="{'active': index == 3 && filter.show}">
      <span>{{ FILTER.time[filter.time]&&FILTER.time[filter.time].name || '文件时间' }}</span>
      <img :src="arrow" class="arrow">
      <ul v-show="index == 3 && filter.show">
        <li @click.stop="chooseFilterTime(-1)">全部时间</li>
        <li v-for="item in filter_time_keys" :key="FILTER.time[item].name" @click.stop="chooseFilterTime(item)">{{FILTER.time[item].name}}</li>
      </ul>
    </div>
    <div class="filter-item from" @click.stop="chooseFilterItem(5)" :class="{'active': index == 5 && filter.show}">
      <span>{{ FILTER.restype[filter.restype]&&FILTER.restype[filter.restype].name || '文件来源' }}</span>
      <img :src="arrow" class="arrow">
      <ul v-show="index == 5 && filter.show">
        <li @click.stop="chooseFilterResType(-1)">全部来源</li>
        <li v-for="item in filter_res_type_keys" :key="FILTER.restype[item].name" @click.stop="chooseFilterResType(item)">{{FILTER.restype[item].name}}</li>
      </ul>
    </div>
    <div class="clear-button" @click="clearFilter">
      <span>清除</span>
      <img :src="clear" class="clear">
    </div>
  </div>
</template>
<script>
import { FILTER } from '@/constants'
const TIME_FILTER_KEYS = Object.keys(FILTER.time).sort()
const TYPE_FILTER_KEYS = Object.keys(FILTER.type).sort()
const SIZE_FILTER_KEYS = Object.keys(FILTER.size).sort()
const SEARCH_TYPE_FILTER_KEYS = Object.keys(FILTER.searchtype).sort()
const RES_TYPE_KEYS = Object.keys(FILTER.restype).sort()


export default {
  props: ['keyword', 'time', 'type', 'size','searchtype', 'restype'],
  data () {
    return {
      arrow: require('@/assets/images/arrow.png'),
      clear: require('@/assets/images/clear.png'),
      index: 0,
      FILTER: FILTER,
      filter_time_keys: TIME_FILTER_KEYS,
      filter_type_keys: TYPE_FILTER_KEYS,
      filter_size_keys: SIZE_FILTER_KEYS,
      filter_res_type_keys: RES_TYPE_KEYS,
      filter_search_type_keys: SEARCH_TYPE_FILTER_KEYS,
    }
  },
  watch: {
    time (data) {
      this.$store.commit('changeFilterTime', data)
    },
    size (data) {
      this.$store.commit('changeFilterSize', data)
    },
    type (data) {
      this.$store.commit('changeFilterType', data)
    },
    searchtype (data) {
      this.$store.commit('changeFilterSearchType', data)
    },
    restype (data) {
      this.$store.commit('changeFilterResType', data)
    },
  },
  computed: {
    filter () {
      return this.$store.state.filter
    },
  },
  methods: {
    chooseFilterItem (index, event) {
      this.$store.commit('showFilter')
      this.index = index
    },
    chooseFilterTime (index) {
      this.$store.commit('changeFilterTime', index)
      this.updatePage()
    },
    chooseFilterSize (index) {
      this.$store.commit('changeFilterSize', index)
      this.updatePage()
    },
    chooseFilterType (index) {
      this.$store.commit('changeFilterType', index)
      this.updatePage()
    },
    chooseFilterSearchType (index) {
      this.$store.commit('changeFilterSearchType', index)
      this.updatePage()
    },
    chooseFilterResType (index) {
      this.$store.commit('changeFilterResType', index)
      this.updatePage()
    },
    clearFilter () {
      this.$store.commit('changeFilter', {
        time: -1,
        type: -1,
        size: -1,
        searchtype: -1,
        restype: -1,
      })
      this.updatePage()
    },
    updatePage () {
      const query = {
        keyword: this.keyword
      }
      if (this.filter.size !== -1) {
        query.size = this.filter.size
      }
      if (this.filter.time !== -1) {
        query.time = this.filter.time
      }
      if (this.filter.type !== -1) {
        query.type = this.filter.type
      }
      if (this.filter.searchtype !== -1) {
        query.searchtype = this.filter.searchtype
      }
      if (this.filter.restype !== -1) {
        query.restype = this.filter.restype
      }
      this.$router.push({ path: 'search', query })
      this.$store.commit('hideFilter')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_var.scss';

.filter-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  label {
    margin-right: 10px;
  }
  .filter-item {
    padding: 4px 15px;
    border-radius: 3px;
    border: 1px solid #ddd;
    margin-right: 15px;
    cursor: pointer;
    position: relative;
    transition: all ease 0.2s;
    .arrow {
      width: 11px;
      vertical-align: 2px;
      margin-left: 4px;
    }
    &:hover,&.active {
      background-color: #eee;
    }
    ul {
      top: 35px;
      left: 0;
      width: 130px;
      z-index: 10;
      position: absolute;
      list-style: none;
      border-radius: 4px;
      border: 1px solid #ddd;
      background-color: #fff;
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
      li {
        border-bottom: 1px solid #ddd;
        padding: 8px 15px;
        transition: all ease 0.2s;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
  .clear-button {
    padding: 6px 15px;
    color: #fff;
    display: inline-block;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    background-color: $theme-color;
    transition: all ease 0.2s;
    &:hover {
      background-color: darken($theme-color, 10%);
    }
    &:active {
      background-color: darken($theme-color, 15%);
    }
    .clear {
      height: 16px;
      vertical-align: middle;
    }
  }
}
@media (max-width: 700px) {
  .filter-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    .label {
      display: none;
    }
    .clear-button {
      display: none;
    }
    .filter-item {
      border-radius: 2px;
      word-spacing: 0;
      font-size: 0;
      width: 24%;
      padding: 6px 2px;
      position: relative;
      text-align: center;
      transition: all ease 0.2s;
      background-color: #eee;
      margin-right: 0;
      &.from {
        display: none;
      }
      .arrow {
        width: 11px;
        vertical-align: 1px;
      }
      span {
        font-size: 11px;
      }
      &.active {
        background-color: #eee;
      }
      ul {
        font-size: 13px;
        top: 30px;
        left: 0;
        width: 90px;
        z-index: 10;
        position: absolute;
        list-style: none;
        border-radius: 4px;
        border: 1px solid #eee;
        background-color: #fff;
        box-shadow: 0 3px 10px rgba(0,0,0,.15);
        li {
          font-size: 11px;
          border-bottom: 1px solid #eee;
          padding: 6px 8px;
          transition: all ease 0.1s;
          &:last-child {
            border-bottom: none;
          }
          &:hover {
            background-color: #eee;
          }
        }
      }
    }
  }
}
</style>
