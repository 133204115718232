<template>
  <div class="feedback-wrap">
    <div
      class="feedback-item"
      v-if="restype != 1"
      @click="changeSearchResType(1)"
    >
      <img
        src="https://res.yimiaopan.com/pan/icons/baidu_icon.png"
        class="img"
      />
      <div class="hover-tip em">
        <p>切换到百度网盘</p>
      </div>
    </div>
    <div
      class="feedback-item"
      v-if="restype != 0"
      @click="changeSearchResType(0)"
    >
      <img src="https://res.yimiaopan.com/pan/icons/ali_icon.png" class="img" />
      <div class="hover-tip em">
        <p>切换到阿里网盘</p>
      </div>
    </div>
    <div
      class="feedback-item"
      v-if="restype != 2"
      @click="changeSearchResType(2)"
    >
      <img
        src="https://res.yimiaopan.com/pan/icons/quark_icon.png"
        class="img"
      />
      <div class="hover-tip em">
        <p>切换到夸克网盘</p>
      </div>
    </div>
    <div
      class="feedback-item"
      v-if="restype != 3"
      @click="changeSearchResType(3)"
    >
      <img src="https://res.yimiaopan.com/pan/icons/xunlei.svg" class="img" />
      <div class="hover-tip em">
        <p>切换到迅雷网盘</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["keyword", "time", "type", "size", "searchtype", "restype"],
  methods: {
    changeSearchResType(type) {
      this.restype = type;
      this.$store.commit("changeFilterResType", this.restype);
      this.updatePage();
    },
    updatePage() {
      const query = {
        keyword: this.keyword,
      };
      if (this.size !== -1) {
        query.size = this.size;
      }
      if (this.time !== -1) {
        query.time = this.time;
      }
      if (this.type !== -1) {
        query.type = this.type;
      }
      if (this.searchtype !== -1) {
        query.searchtype = this.searchtype;
      }
      if (this.restype !== -1) {
        query.restype = this.restype;
      }
      this.$router.push({ path: "search", query });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";

.feedback-wrap {
  position: fixed;
  bottom: 100px;
  width: 60px;
  display: inline-flex;
  flex-direction: column;

  .feedback-item {
    width: 60px;
    height: 60px;
    display: flex;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #fff;
    box-shadow: 3px 3px 14px 0 rgba(0, 0, 0, 0.2),
      -3px -3px 14px 0 rgba(0, 0, 0, 0.2);

    .img {
      width: 36px;
    }

    &:hover {
      .hover-tip {
        visibility: visible;
        opacity: 1;
        bottom: 10px;
      }
    }

    .hover-tip {
      width: 140px;
      padding: 10px;
      display: flex;
      visibility: hidden;
      opacity: 0;
      flex-direction: column;
      align-items: center;
      position: absolute;
      text-align: center;
      border-radius: 4px;
      left: 74px;
      bottom: -60px;
      border: 1px solid #eee;
      background-color: #fff;
      transition: all ease 0.2s 0.1s;
      box-shadow: 1px 1px 19px 0 rgba(0, 0, 0, 0.05),
        -1px -1px 19px 0 rgba(0, 0, 0, 0.05);

      &:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        left: -12px;
        bottom: 10px;
        z-index: 1;
        border-top: 10px solid transparent;
        border-right: 12px solid #eee;
        border-bottom: 10px solid transparent;
      }

      &:before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        left: -10px;
        bottom: 10px;
        z-index: 2;
        border-top: 10px solid transparent;
        border-right: 12px solid #fff;
        border-bottom: 10px solid transparent;
      }

      .text {
        font-weight: bold;
        color: #333;
      }
    }
  }
}

@media (max-width: 700px) {
  .feedback-wrap {
    width: 44px;

    .feedback-item {
      height: 44px;
      width: 44px;

      .img {
        width: 28px;
      }
    }
  }
}
</style>
