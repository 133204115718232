import mime from 'mime-types'
import * as FILE_TYPES from '@/constants'

export default (filename) => {
  const mimeType = mime.lookup(filename)
  if (!mimeType) {
    return FILE_TYPES.FILE_TYPE_UNKNOWN
  }
  if (
    mimeType.match('ms-powerpoint') ||
    mimeType.match('officedocument.presentationml.presentation')
  ) {
    return FILE_TYPES.FILE_TYPE_PPT
  }
  if (
    mimeType.match('ms-excel') ||
    mimeType.match('officedocument.spreadsheetml.sheet')
  ) {
    return FILE_TYPES.FILE_TYPE_XLS
  }
  if (
    mimeType.match('msword') ||
    mimeType.match('officedocument.wordprocessingml.document')
  ) {
    return FILE_TYPES.FILE_TYPE_DOC
  }
  if (mimeType.match('compressed') || mimeType.match('zip') || mimeType.match('gzip')) {
    return FILE_TYPES.FILE_TYPE_ZIP
  }
  if (mimeType.match('application/pdf')) {
    return FILE_TYPES.FILE_TYPE_PDF
  }

  if (mimeType.match('audio/')) {
    return FILE_TYPES.FILE_TYPE_AUDIO
  }

  if (mimeType.match('video/')) {
    return FILE_TYPES.FILE_TYPE_VIDEO
  }

  if (mimeType.match('image/')) {
    return FILE_TYPES.FILE_TYPE_IMG
  }

  return FILE_TYPES.FILE_TYPE_UNKNOWN
}
