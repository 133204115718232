<template>
   <div id="hot" v-if="hot && (hot.length > 0)">
      <div class="inner">
        <router-link :to="`/main/search?kw=${item}`" class="hot-item" v-for="item in hot" :key="item">{{item}}</router-link>
      </div>
    </div>
</template>
<script>
import { getRecommandWords } from '../api/search'
export default {
  data () {
    return {
      hot: []
    }
  },
  async mounted() {
    this.hot = await getRecommandWords()
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
#hot{
  .inner {
    font-size: 0;
    margin-right: -10px;
    text-align: center;
    display: flex; 
    flex-wrap: wrap;
    justify-content: center;
  }
  .hot-item {
    font-size: 13px;
    padding: 4px 10px;
    border-radius: 4px;
    margin-right: 15px;
    margin-bottom: 10px;
    text-decoration: none;
    transition: all ease 0.2s;
    color: rgba($theme-color, 0.8);
    background-color: #f5f5f5;
    &:hover {
      color: #fff;
      background-color: $theme-color;
    }
  }
}
</style>
