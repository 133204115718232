<template>
  <div class="resource-item-wrap" :class="itemValid">
    <div class="resource-item">
      <img
        :src="require(`@/assets/images/${data.res.fileType}.png`)"
        class="filetype"
        :alt="
          (data.highs && data.highs.filename && data.highs.filename[0]) ||
            data.res.filename ||
            1
        "
      />
      <div class="resource-info">
        <h1 class="resource-title" v-if="!subscribed">
          <a
            href="javascript:void(0);"
            @click="openLogin"
            v-html="
              (data.highs && data.highs.filename && data.highs.filename[0]) ||
                data.res.filename ||
                1
            "
          />
        </h1>
        <h1
          class="resource-title"
          v-if="subscribed && userinfo && userinfo.expired"
        >
          <a
            href="javascript:void(0);"
            @click="checkVip"
            v-html="
              (data.highs && data.highs.filename && data.highs.filename[0]) ||
                data.res.filename ||
                1
            "
          />
        </h1>
        <h1
          class="resource-title"
          v-if="subscribed && userinfo && !userinfo.expired"
        >
          <a
            target="_blank"
            :class="itemValid"
            :href="
              data.url
                ? data.url
                : `/#/main/detail/${data.res.id ||
                    data.res.Id}?keyword=${keyword}&type=${
                    data.res.type
                  }&parent=${data.res.parent}`
            "
            v-html="
              (data.highs && data.highs.filename && data.highs.filename[0]) ||
                data.res.filename ||
                1
            "
          ></a>
        </h1>
        <div
          class="detail-wrap"
          v-if="data.res.filelist && data.res.filelist.length > 0"
        >
          <p
            v-for="(detailItem, detailIndex) in data.res.filelist.slice(0, 5)"
            class="detail-item-wrap"
            :key="`detail${detailIndex}`"
          >
            <span
              class="detail-item-title"
              v-html="
                (data.highs &&
                  data.highs['filelist.filename'] &&
                  data.highs['filelist.filename'][detailIndex]) ||
                  detailItem.filename
              "
            ></span>
            <span v-if="detailItem.isdir !== 1">
              - {{ detailItem.size | bytesToSize }}</span
            >
          </p>
          <p class="detail-item-wrap" v-if="data.res.filelist.length > 5">
            <span class="">......</span>
          </p>
        </div>
        <div class="resource-meta">
          <span class="meta-item"
            ><span class="label">文件大小</span>
            <span class="em">{{ data.res.size | bytesToSize }}</span>
          </span>
          <img
            v-if="data.res.type === 'aliyundrive'"
            src="https://res.yimiaopan.com/pan/icons/aliyundrive.png"
            class="tag"
          />
          <img
            v-if="data.res.type === 'quark'"
            src="https://res.yimiaopan.com/pan/icons/quark.png"
            class="tag"
          />
          <img
            v-if="data.res.type === 'baidu'"
            src="https://res.yimiaopan.com/pan/icons/baidu.png"
            class="tag"
          />
          <img
            v-if="data.res.type === 'xunleipan'"
            src="https://res.yimiaopan.com/pan/icons/xunlei.png"
            class="tag"
          />
          <span
            v-if="!status || status === -2"
            class="warning em"
            style="padding-left: 6px;"
            >资源状态检测中....</span
          >
          <span v-if="status === -1" class="em" style="padding-left: 6px;"
            >资源状态未知</span
          >
          <span
            v-if="status === 1"
            class="success em"
            style="padding-left: 6px;"
            >资源有效</span
          >
        </div>
      </div>
      <div class="other-info">
        <p class="time">{{ data.res.ctime.substr(0, 10) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { RESOURCE_FROM } from "@/constants";

export default {
  props: ["data", "keyword", "valid", "subscribed", "status"],
  data() {
    return {
      from: RESOURCE_FROM.sopan_spider,
    };
  },
  mounted() {
    if (this.data.res.client) {
      this.from =
        RESOURCE_FROM[this.data.res.client] || RESOURCE_FROM.sopan_spider;
    }
  },
  computed: {
    itemValid() {
      if (!this.valid == 1) {
        return "valid";
      }
      if (this.valid == 0) {
        return "invalid";
      }
      return "";
    },
  },
  methods: {
    openLogin() {
      this.$emit("login");
    },
    checkVip() {
      this.$router.push({
        name: "notVip",
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_var.scss";

.resource-item-wrap {
  color: inherit;

  &:hover {
    text-decoration: none;
  }

  &.invalid {
    &:hover {
      text-decoration: none;

      h1 a {
        color: #999 !important;
      }
    }

    .resource-item {
      .filetype {
        filter: grayscale(100%);
      }

      .resource-info {
        h1 {
          a {
            text-decoration: line-through;
            color: #999;

            &:hover {
              color: #999 !important;
            }
          }
        }

        mark {
          color: inherit;
          text-decoration: underline;
        }

        .detail-wrap {
          .detail-item-wrap {
            color: #999 !important;
            text-decoration: line-through !important;
          }
        }
      }
    }
  }
}

.resource-item {
  padding: 20px 0;
  display: flex;
  border-bottom: 1px dashed #eee;

  .filetype {
    height: 30px;
    margin-right: 15px;
    flex: none;
  }

  .resource-info {
    flex: auto;

    h1 {
      word-break: break-all;
      font-size: 16px;
      color: #333;
      line-height: 1.3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;

      a {
        color: inherit;
        cursor: pointer;

        &:hover {
          color: $theme-color;
          text-decoration: underline;
        }
      }
    }

    mark {
      color: lighten($theme-color, 5%);
      background-color: transparent;
      text-decoration: underline;
    }

    .detail-wrap {
      margin-top: 15px;
      padding: 10px;
      border-radius: 2px;
      background-color: #f8f8f8;
      width: 100%;

      .detail-item-wrap {
        span {
          font-size: 13px;
          display: inline;
          line-height: 1.8;
          color: #666;
        }

        .detail-item-title {
          font-weight: normal;
          position: relative;
          padding-left: 15px;
          display: inline;
          word-break: break-all;

          &:before {
            content: "|-";
            position: absolute;
            top: -6px;
            left: 0;
          }
        }
      }
    }

    .resource-meta {
      margin-top: 15px;
      font-size: 12px;
      color: #666;
      position: relative;
      display: flex;
      align-items: center;

      .tag {
        height: 24px;
        margin-left: 10px;
        // border: 1px solid #ddd;
        border-radius: 2px;
        // background-color: #f8f8f8;
        padding: 3px 5px;
      }

      .meta-item {
        margin-right: 6px;
        display: inline-block;
        border-radius: 2px;
        padding-right: 4px;
        border: 1px solid #ddd;
        background-color: #f8f8f8;

        .label {
          display: inline-block;
          padding: 2px 4px;
          margin-right: 4px;
          background: #f5f5f5;
          border-right: 1px solid #ddd;
        }

        .em {
          font-weight: bold;
        }
      }
    }
  }

  .other-info {
    min-width: 120px;

    .time {
      text-align: right;
      color: #999;
      font-size: 12px;

      a {
        color: inherit;
        font-weight: normal;
      }
    }
  }

  .button-wrap {
    display: inline;
    text-align: center;

    .button-inner {
      margin-right: 5px;
      margin-left: 5px;
      display: inline-block;

      &:last-child {
        margin-right: 0;
      }

      &.baidu-button-inner {
        .button {
          background-color: #666;

          &:hover {
            background-color: darken(#666, 10%);
          }
        }
      }
    }

    .button {
      display: inline-block;
      color: #fff;
      height: 23px;
      line-height: 23px;
      width: 100px;
      border-radius: 2px;
      background-color: $theme-color;
      transition: background-color ease 0.3s;
      text-decoration: none;

      &:hover {
        background-color: darken($theme-color, 10%);
      }

      &.invalid {
        color: #ddd;
        text-decoration: line-through;
      }
    }
  }
}

@media (max-width: 700px) {
  .resource-item {
    display: block;
    position: relative;
    padding-left: 26px;
    padding: 10px 0 10px 26px;

    .filetype {
      position: absolute;
      top: 15px;
      height: 16px;
      left: 0;
      width: auto;
    }

    .resource-info {
      h1 {
        font-size: 14px;
      }

      .detail-wrap {
        margin-top: 5px;

        .detail-item-wrap {
          span {
            font-size: 11px;
          }
        }
      }

      .resource-meta {
        margin-top: 10px;

        .meta-item {
          margin-right: 4px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .other-info {
      margin-top: 10px;
      margin-left: 0;

      .time {
        text-align: left;
      }
    }
  }
}
</style>
