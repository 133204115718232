<template>
  <div class="pager-wrap">
    <div class="pc-pager-wrap">
      <router-link class="pager-item" :to="`${pathPrefix}?keyword=${keyword}&page=${pageCurrent-1}`" v-if="pageCurrent>1">上一页</router-link>
      <template v-if="maxPageTotal <= 10">
        <router-link class="pager-item" :to="`${pathPrefix}?keyword=${keyword}&page=${index}`" :class="{'active': index == pageCurrent}" v-for="index in pageArr" :key="index">
          {{index}}
        </router-link>
      </template>
      <template v-else>
        <template v-if="pageCurrent - 1 <= 5">
          <router-link :to="`${pathPrefix}?keyword=${keyword}&page=${index}`" class="pager-item" :class="{'active': index == pageCurrent}" v-for="index in pageArr" :key="index">
            {{index}}
          </router-link>
          <span class="pager-mark pager-item">...</span>
          <router-link :to="`${pathPrefix}?keyword=${keyword}&page=${maxPageTotal-1}`" class="pager-item">{{maxPageTotal-1}}</router-link>
          <router-link :to="`${pathPrefix}?keyword=${keyword}&page=${maxPageTotal}`" class="pager-item">{{maxPageTotal}}</router-link>
        </template>
        <template v-else-if="maxPageTotal - pageCurrent <= 5">
          <router-link :to="`${pathPrefix}?keyword=${keyword}&page=1`" class="pager-item">1</router-link>
          <router-link :to="`${pathPrefix}?keyword=${keyword}&page=2`" class="pager-item">2</router-link>
          <span class="pager-mark pager-item">...</span>
          <router-link :to="`${pathPrefix}?keyword=${keyword}&page=${index}`" class="pager-item" :class="{'active': index == pageCurrent}" v-for="index in pageArr" :key="index">
            {{index}}
          </router-link>
        </template>
        <template v-else>
          <router-link :to="`${pathPrefix}?keyword=${keyword}&page=1`" class="pager-item">1</router-link>
          <router-link :to="`${pathPrefix}?keyword=${keyword}&page=2`" class="pager-item">2</router-link>
          <span class="pager-mark pager-item">...</span>
          <router-link :to="`${pathPrefix}?keyword=${keyword}&page=${index}`" class="pager-item" :class="{'active': index == pageCurrent}" v-for="index in pageArr" :key="index">
            {{index}}
          </router-link>
          <span class="pager-mark pager-item">...</span>
          <router-link :to="`${pathPrefix}?keyword=${keyword}&page=${maxPageTotal-1}`" class="pager-item">{{maxPageTotal-1}}</router-link>
          <router-link :to="`${pathPrefix}?keyword=${keyword}&page=${maxPageTotal}`" class="pager-item">{{maxPageTotal}}</router-link>
        </template>
      </template>
      <router-link :to="`${pathPrefix}?keyword=${keyword}&page=${pageCurrent + 1}`" class="pager-item" v-if="pageCurrent < maxPageTotal">下一页</router-link>
    </div>
    <div class="mobile-pager-wrap">
      <router-link class="pager-item" :to="`${pathPrefix}?keyword=${keyword}&page=${pageCurrent-1}`" v-if="pageCurrent>1">上一页</router-link>
      <router-link :to="`${pathPrefix}?keyword=${keyword}&page=${pageCurrent + 1}`" class="pager-item" v-if="pageCurrent < maxPageTotal">下一页</router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: ['pageCurrent', 'pageTotal', 'keyword'],
  data() {
    return {
      pathPrefix: '/main/search'
    }
  },
  computed: {
    maxPageTotal () {
      return this.pageTotal
    },
    pageArr(){
      let arr = []
      if (this.maxPageTotal <= 10) {
        for (let i = 0; i < this.maxPageTotal; i++) {
          arr.push(i+1)
        }
      } else {
        if (this.pageCurrent - 1 <= 5) {
          for (let i = 1; i <= 7; i++) {
            arr.push(i)
          }
        } else if (this.maxPageTotal - this.pageCurrent <= 5) {
          for (let i = this.maxPageTotal - 6; i <= this.maxPageTotal; i++) {
            arr.push(i)
          }
        } else {
          for (let i = this.pageCurrent - 2; i <= this.pageCurrent - 0 +2; i++) {
            arr.push(i)
          }
        }
      }
      return arr
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_var.scss';
.pager-wrap {
  text-align: center;
  margin-top: 30px;
  .pc-pager-wrap {
    display: flex;
    justify-content: flex-start;
  }
  .mobile-pager-wrap {
    display: none;
  }
  .pager-item {
    color: #666;
    font-size: 13px;
    display: inline-block;
    padding: 6px 12px;
    border: 1px solid #ddd;
    border-right: none;
    text-decoration: none;
    background-color: #fff;
    transition: background-color ease 0.2s;
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-right: 1px solid #ddd;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &:hover, &.active {
      cursor: pointer;
      font-weight: bold;
      color: #fff;
      background-color: $theme-color;
      border-color: $theme-color;
    }
    &.pager-mark {
      cursor: default;
      font-weight: normal;
      background-color: #fff;
      &:hover {
        border-color: #ddd;
        border-right: none;
        color: inherit;
        background-color: #fff;
      }
    }
  }
}
@media (max-width: 700px) { 
  .pager-wrap {
    margin-top: 15px;
    .pc-pager-wrap {
      display: none;
    }
    .mobile-pager-wrap {
      display: flex;
      justify-content: center;
      .pager-item {
        width: 40%;
      }
    }
  }
}
</style>
